import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import Footer from '../components/Footer';
import Header, { headerAnim } from '../components/Header';
import useLocoScroll from '../hooks/useLocoScroll';
import Loader, { loaderAnim } from '../components/Loader';
import { Link } from 'react-router-dom';
import { MouseContext } from '../components/CustomCursor/CursorManager';
import featuredProjectsData from '../featuredProjectsData.json';
export default function Case() {
	const { slug } = useParams();
	const [featuredProjects] = useState(featuredProjectsData.reverse());

	const [preloader, setPreloader] = useState(true);
	const preload = () => {
		setPreloader(false);
	};
	const scrollRef = useLocoScroll(!preloader);
	useEffect(() => {
		const timer = setTimeout(() => {
			preload();
		}, 2000);

		return () => clearTimeout(timer);
	}, [scrollRef]);

	const { cursorChangeHandler } = useContext(MouseContext);
	const hoverStart = (_, type) => {
		cursorChangeHandler(_, type);
	};
	const hoverStop = (_, type) => {
		cursorChangeHandler(_, type);
	};

	useLayoutEffect(() => {
		loaderAnim(preloader);
		headerAnim(preloader);
	}, [preloader]);

	const tablet = window.innerWidth < 768;

	// if (featuredProjects.length < 1) return <h2>Loading</h2>;
	if (preloader || featuredProjects.length < 1) return <Loader />;

	const project = featuredProjects?.find((p) => p.fields.slug === slug);
	const thisProjectIndex = featuredProjects.indexOf(project);
	const nextProjectIndex =
		thisProjectIndex + 1 >= featuredProjects.length ? 0 : thisProjectIndex + 1;
	const nextProject = featuredProjects[nextProjectIndex].fields.slug;
	const { client, projectName, link, year, role } = project?.fields;
	const featuredImage = project.fields.featuredImage.fields.file.url;
	const infoImage = project.fields.infoImage.fields.file.url;
	const laptopView = project.fields.laptopView.fields.file.url;
	const phoneView1 = project.fields.phoneView1.fields.file.url;
	const phoneView2 = project.fields.phoneView2.fields.file.url;
	const caseData = {
		name: projectName,
		image: featuredImage,
	};

	return (
		<>
			{/* <Loader /> */}
			{!preloader && (
				<main ref={scrollRef}>
					<Header type="case" caseData={caseData} />

					{/* CASE IMAGES */}
					<section className="case__images">
						<div className="container pt-2 pb-2">
							<div className="info__image" id="info">
								<figure className="info__image-img">
									<img src={infoImage} alt="case Info" />
								</figure>
								{tablet ? (
									<div className="info__image-info" data-scroll>
										<h4 className="heading year">Year: {year}</h4>
										<h4 className="heading client">Client: {client}</h4>
										<h4 className="heading role">Role: {role}</h4>
										<a href={link} target="_blank" rel="noreferrer" className="live">
											See Live
										</a>
									</div>
								) : (
									<div
										className="info__image-info"
										data-scroll
										data-scroll-sticky
										data-scroll-target="#info"
									>
										<h4 className="heading year">Year: {year}</h4>
										<h4 className="heading client">Client: {client}</h4>
										<h4 className="heading role">Role: {role}</h4>
										<a
											href={link}
											target="_blank"
											rel="noreferrer"
											className="live"
											onMouseEnter={() => hoverStart(null, 'logo')}
											onMouseLeave={() => hoverStop(null, '')}
										>
											See Live
										</a>
									</div>
								)}
							</div>
						</div>
						<div className="container container__small pt-10 pb-10">
							<img src={laptopView} alt="laptop view" className="shadow" />
						</div>
						<div className="container container__small text-center">
							<div className="phone__image">
								{tablet ? (
									<figure
										className="phone__image-img phone__image-img--1"
										data-scroll
										data-scroll-speed="3"
									>
										<img src={phoneView1} alt="mobile view" className="shadow" />
									</figure>
								) : (
									<figure
										className="phone__image-img phone__image-img--1"
										data-scroll
										data-scroll-speed="-1"
									>
										<img src={phoneView1} alt="mobile view" className="shadow" />
									</figure>
								)}
								<figure
									className="phone__image-img phone__image-img--2"
									data-scroll
									data-scroll-speed="3"
								>
									<img src={phoneView2} alt="mobile view" className="shadow" />
								</figure>
							</div>

							<Link
								to={`/project/${nextProject}`}
								className="btn btn__primary"
								onMouseEnter={() => hoverStart(null, 'button')}
								onMouseLeave={() => hoverStop(null, '')}
							>
								View Next
							</Link>
						</div>
					</section>

					<Footer />
				</main>
			)}
		</>
	);
}
