import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export const MouseContext = createContext({
	cursorType: '',
	cursorChangeHandler: () => {},
});

const MouseContextProvider = (props) => {
	const [cursorType, setCursorType] = useState('');
	const [src, setSrc] = useState('');

	const cursorChangeHandler = (image, type = '') => {
		setCursorType(type);
		setSrc(image);
	};

	const location = useLocation();

	useEffect(() => {
		setCursorType('');
		setSrc('');
	}, [location.pathname]);

	return (
		<MouseContext.Provider
			value={{
				cursorType,
				cursorChangeHandler: cursorChangeHandler,
				src,
			}}
		>
			{props.children}
		</MouseContext.Provider>
	);
};

export default MouseContextProvider;
