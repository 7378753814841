import { useContext, useState } from 'react';

import { MouseContext } from './CustomCursor/CursorManager';
import otherProjectsData from '../otherProjects.json';

export default function OtherProjects() {
  const [otherProjects] = useState(otherProjectsData);

  const { cursorChangeHandler } = useContext(MouseContext);
  const hoverStart = (src, type = '') => {
    cursorChangeHandler(src, type);
  };
  const hoverStop = (src, type = '') => {
    cursorChangeHandler(src, type);
  };

  return (
    <section className="other-projects">
      <div className="container">
        <h2 className="section__heading">
          Other <span className="outline__text">Projects</span>
          <span className="sub__heading">
            (Freelance, Experiment, Hobby, Contest)
          </span>
        </h2>
      </div>
      <div className="container container__small">
        <div className="project__list">
          {otherProjects?.map((item, index) => {
            const { link, projectName, projectTags, projectImage } =
              item.fields;
            return (
              <a
                key={projectName + index}
                href={link}
                target="_blank"
                rel="noreferrer"
                className="project__list-item"
                onMouseEnter={() => hoverStart(projectImage.fields.file.url)}
                onMouseLeave={() => hoverStop('')}>
                <div className="left">
                  <h3 className="project__title">{projectName}</h3>
                  <p className="project__tags">{projectTags}</p>
                </div>
                <svg>
                  <use xlinkHref="/assets/icons.svg#icon-arrow"></use>
                </svg>
              </a>
            );
          })}
        </div>
        <a
          href="https://github.com/main1479"
          target="_blank"
          rel="noreferrer"
          className="btn btn__primary"
          onMouseEnter={() => hoverStart(null, 'button')}
          onMouseLeave={() => hoverStop(null, '')}>
          View All
        </a>
      </div>
    </section>
  );
}
