import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useRef } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';
import { useLocation } from 'react-router';

gsap.registerPlugin(ScrollTrigger);
export let locoScroll = null;

export default function useLocoScroll(start) {
	const scrollRef = useRef(null);
	const location = useLocation();
	useEffect(() => {
		if (!start) return;

		locoScroll = new LocomotiveScroll({
			el: scrollRef.current,
			multiplier: 1.1,
			class: 'is-inview',
			smooth: true,
			resetNativeScroll: true,
			smartphone: {
				smooth: false,
			},
			tablet: {
				smooth: true,
				multiplier: 2,
			},
		});

		locoScroll.on('scroll', (event) => {
			ScrollTrigger.update();
		});

		ScrollTrigger.scrollerProxy(scrollRef.current, {
			scrollTop(value) {
				if (locoScroll) {
					return arguments.length
						? locoScroll.scrollTo(value, 0, 0)
						: locoScroll.scroll.instance.scroll.y;
				}
				return null;
			},
			scrollLeft(value) {
				if (locoScroll) {
					return arguments.length
						? locoScroll.scrollTo(value, 0, 0)
						: locoScroll.scroll.instance.scroll.x;
				}
				return null;
			},
		});

		const lsUpdate = () => {
			if (locoScroll) {
				locoScroll.update();
				ScrollTrigger.update();
			}
		};
		requestAnimationFrame(lsUpdate);

		const timer = setTimeout(() => {
			lsUpdate();
		}, 2000);

		ScrollTrigger.addEventListener('refresh', lsUpdate);
		ScrollTrigger.refresh();

		return () => {
			if (locoScroll) {
				ScrollTrigger.removeEventListener('refresh', lsUpdate);
				clearInterval(timer);
				locoScroll.destroy();
				locoScroll = null;
				console.log('Kill', locoScroll);
			}
		};
	}, [location, start]);

	return scrollRef;
}
