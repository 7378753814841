import gsap from 'gsap';
import { Link } from 'react-router-dom';
import SplitText from '../utils/splitText';
import Nav from './Nav';

export const headerAnim = (loader) => {
	if (loader) return;
	const tl = gsap.timeline({});
	const bigLines = gsap.utils.toArray('.impact .line span');
	const split = new SplitText(bigLines);
	const splitName = new SplitText('#smallName');
	tl.from(splitName.chars, {
		duration: 1,
		opacity: 0,
		delay: 1,
		y: 50,
		skewY: 10,
		ease: 'power2.out',
		stagger: {
			amount: 0.2,
		},
	}).from(split.chars, {
		duration: 1,
		opacity: 0,
		y: 500,
		delay: -1,
		ease: 'power2.out',
		stagger: {
			amount: 1,
		},
	});

	const splitCase = new SplitText('.case__banner-title');
	tl.from(splitCase.chars, {
		duration: 1,
		opacity: 0,
		delay: -0.5,
		y: 100,
		ease: 'power2.out',
		stagger: {
			amount: 0.2,
		},
	});
};

export default function Header({ type, caseData = {} }) {
	return (
		<header className="header">
			<Nav type={type} />
			{type === 'home' && (
				<div className="container">
					<h2 id="smallName" className="heading  mt-5" data-scroll>
						Mainul Islam
					</h2>
					<h1 className="impact home__intro">
						<div className="line">
							<span>PASSIONATE</span>
						</div>
						<div className="line">
							<span className="outline__text">FRONTEND DEVELOPER</span>
						</div>
					</h1>
				</div>
			)}
			{type === 'about' && (
				<div className="container header__content">
					<h1 className="impact about__heading">
						<div className="line">
							<span className="span">Not just</span>
						</div>
						<div className="line">
							<span className="span outline__text">your average</span>
						</div>
						<div className="line">
							<span className="span">Frontend dev.</span>
						</div>
					</h1>
				</div>
			)}
			{type === 'case' && (
				<div className="container text-center">
					<div className="case__banner">
						<img src={caseData.image} alt={caseData.name} className="case__banner-img" />

						<h1 className="case__banner-title">{caseData.name}</h1>
					</div>
				</div>
			)}
			{type === '404' && (
				<div className="container text-center not__found">
					<h1 className="impact not-found">
						<div className="line">
							<span>OOPS</span>
						</div>
						<div className="line">
							<span className="outline__text">404</span>
						</div>
					</h1>
					<h3 className="heading mt-3">Hey kid! looks like you are lost!</h3>
					<Link to="/" className="btn btn__primary not-found">
						{' '}
						Go Home{' '}
					</Link>
				</div>
			)}
		</header>
	);
}
