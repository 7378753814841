import Footer from '../components/Footer';
import Header, { headerAnim } from '../components/Header';
import useLocoScroll from '../hooks/useLocoScroll';
import gsap from 'gsap';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useOnScreen from '../hooks/useOnScreen';
import Loader, { loaderAnim } from '../components/Loader';
import { MouseContext } from '../components/CustomCursor/CursorManager';
import { useContext } from 'react';

export default function About() {
	const [preloader, setPreloader] = useState(true);
	const { cursorChangeHandler } = useContext(MouseContext);
	const hoverStart = (_, type) => {
		cursorChangeHandler(_, type);
	};
	const hoverStop = (_, type) => {
		cursorChangeHandler(_, type);
	};

	const skillsRef = useRef(null);

	const [skillsListReveal, setSkillsListReveal] = useState(false);
	const skillsListOnScreen = useOnScreen(skillsRef);

	useEffect(() => {
		if (skillsListOnScreen) setSkillsListReveal(skillsListOnScreen);
	}, [skillsListOnScreen]);

	const scrollRef = useLocoScroll(!preloader);
	const preload = () => {
		setPreloader(false);
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			preload();
		}, 500);

		return () => clearTimeout(timer);
	}, []);

	useLayoutEffect(() => {
		loaderAnim(preloader);
		headerAnim(preloader);

		if (skillsListReveal) {
			const lines = gsap.utils.toArray('.skills__list .heading');

			gsap.to(lines, {
				duration: 0.5,
				y: 0,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
			});
		}
	}, [preloader, skillsListReveal]);
	return (
		<>
			<Loader />
			<main ref={scrollRef}>
				<Header type="about" />
				{/* ABOUT ME */}
				<section className="about-me">
					<div className="container" id="name__container">
						<h3
							className="heading nameIntro"
							data-scroll
							data-scroll-direction="horizontal"
							data-scroll-speed="-2"
							data-scroll-target="#name__container"
						>
							Hi, my name is
						</h3>
						<h2 className="impact name">
							<div className="my__image"></div>
							<div className="line">
								<span className="outline__text">Mainul</span>
							</div>
							<div className="line">
								<span className="outline__text ml-auto">Islam</span>
							</div>
						</h2>
						<h3
							className="heading ml-auto"
							data-scroll
							data-scroll-direction="horizontal"
							data-scroll-speed="8"
							data-scroll-target="#name__container"
						>
							Frontend web developer
						</h3>
					</div>
					<div className="container container__small pt-15">
						<p className="about__text mb-3" data-scroll>
							Hello awesome people, welcome to my Portfolio. I hope you're enjoying browsing my
							portfolio. First off, please allow me to say something about myself.
						</p>
						<p className="about__text mb-3" data-scroll>
							I started working as a freelance frontend developer in 2019. And in 2020 I started a
							full time remote job as a frontend developer at a freelance marketplace based agency.
							Where my main job was to convert designs into code (HTML template) and refactor / fix
							other team members' code.
						</p>
						<p className="about__text mb-3" data-scroll>
							Towards the end of 2020 I quit my job and a few months later became a full-time
							learner. And for the whole of 2021, I've spent an average of 14-18 hours a day
							learning web development (I know it might sound crazy to you, but the fact is, you
							never get bored doing what you love to do).
						</p>
						<p className="about__text" data-scroll>
							Since I already had experience working on 70+ real projects, I had good skills in
							HTML, CSS, SCSS, and JavaScript Basic DOM manipulation. So I focus more on modern tech
							stacks (Advance JavaScript, React, Next.js, Redux, etc.)
						</p>
						<h3 className="heading current-status mt-6">
							* Currently working at{' '}
							<a
								href="https://conversion.com/"
								rel="noreferrer"
								target="_blank"
								onMouseEnter={() => hoverStart(null, 'logo')}
								onMouseLeave={() => hoverStop(null, '')}
							>
								Conversion.com
							</a>
						</h3>
						{/* <h3 className="heading current-status mt-6">
							* Currently freelancing, and seeking job opportunities.
						</h3> */}
					</div>

					<div className="animated__headline">
						<div className="m-scroll">
							<div className="m-scroll__title">
								<div>
									<h2>ADDICTED to FRONT-END Web Development</h2>
									<h2>ADDICTED to FRONT-END Web Development</h2>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* SKILLS */}
				<section className="skills">
					<div className="container">
						<div className="skills__grid" ref={skillsRef}>
							<div className="skills__grid-item" data-scroll>
								<h2 className="section__heading section__heading-small">
									I'm <span className="outline__text">Comfortable</span> With
								</h2>
								<div className="skills__list mt-5">
									<h4 className="heading">- JavaScript(ES6+)</h4>
									<h4 className="heading">- React</h4>
									<h4 className="heading">- Next.js</h4>
									<h4 className="heading">- SCSS</h4>
									<h4 className="heading">- CSS</h4>
									<h4 className="heading">- HTML</h4>
									<h4 className="heading">- TailwindCSS</h4>
									<h4 className="heading">- Bootstrap</h4>
								</div>
							</div>
							<div className="skills__grid-item" data-scroll>
								<h2 className="section__heading section__heading-small">
									I Also <span className="outline__text">Can Work</span> With
								</h2>
								<div className="skills__list mt-5">
									<h4 className="heading">- TypeScript</h4>
									<h4 className="heading">- GraphQL</h4>
									<h4 className="heading">- NodeJS</h4>
									<h4 className="heading">- Express.js</h4>
									<h4 className="heading">- Firebase</h4>
									<h4 className="heading">- MongoDB</h4>
									<h4 className="heading">- WordPress</h4>
									<h4 className="heading">- HeadlessCMSs</h4>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</main>
		</>
	);
}
