import gsap from 'gsap';
import React from 'react';
// import { motion } from 'framer-motion';

const generateMessage = () => {
	const messages = [
		'You are amazing',
		'Just a sec..',
		"Ahaa, It's Loading",
		'Wow, you are here!',
		'Almost There...',
		'Please wait...',
	];
	const randomNum = Math.floor(Math.random() * messages.length);
	return messages[randomNum];
};

export const loaderAnim = (loading) => {
	if (!loading) return;
	const tl = gsap.timeline();
	tl.to('.loader .left', {
		duration: 1.5,
		width: 0,
		ease: 'power2.out',
	});
	tl.to('.loader .right', {
		duration: 1.5,
		width: 0,
		delay: -1.5,
		ease: 'power2.out',
	}).to('.loader', {
		duration: 0.5,
		opacity: 0,
	});
};

export default function Loader({ loading }) {
	return (
		<div className={loading ? 'loader loading' : 'loader loaded'}>
			<div className="left">&nbsp;</div>
			<div className="right">&nbsp;</div>
			<h2 className="message">{generateMessage()}</h2>
		</div>
	);
}
