import ScrollTrigger from 'gsap/ScrollTrigger';
import { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { locoScroll } from '../hooks/useLocoScroll';
import useOnScreen from '../hooks/useOnScreen';
import { MouseContext } from './CustomCursor/CursorManager';

export default function Nav({ type }) {
	const { cursorChangeHandler } = useContext(MouseContext);
	const ref = useRef(null);
	const onScreen = useOnScreen(ref);
	const hoverStart = (_, type) => {
		cursorChangeHandler(_, type);
	};
	const hoverStop = (_, type) => {
		cursorChangeHandler(_, type);
	};

	useEffect(() => {
		if (onScreen) {
			ScrollTrigger.update();
			if (locoScroll) {
				ScrollTrigger.update();
				locoScroll.update();
			}
		}
	}, [onScreen]);
	return (
		<nav className={onScreen ? 'nav  on-screen' : 'nav'} ref={ref}>
			<div className="container nav__container">
				<Link
					to="/"
					className="logo"
					onMouseEnter={() => hoverStart(null, 'logo')}
					onMouseLeave={() => hoverStop(null, '')}
				>
					<img src="/assets/logo.png" alt="Mainul Islam" />
				</Link>

				<ul className="nav__menu">
					{type === 'about' ? (
						<Link
							to="/"
							className="nav__menu-link"
							onMouseEnter={() => hoverStart(null, 'logo')}
							onMouseLeave={() => hoverStop(null, '')}
						>
							Home
						</Link>
					) : (
						<Link
							to="/about"
							className="nav__menu-link"
							onMouseEnter={() => hoverStart(null, 'logo')}
							onMouseLeave={() => hoverStop(null, '')}
						>
							About
						</Link>
					)}
					{type === 'home'}
					<a
						href="mailto:m.main2402@gmail.com"
						className="nav__menu-link"
						onMouseEnter={() => hoverStart(null, 'logo')}
						onMouseLeave={() => hoverStop(null, '')}
					>
						Say Hi <img src="/assets/wave.png" alt="Email Me" />
					</a>
				</ul>
			</div>
		</nav>
	);
}
