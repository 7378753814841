import { useContext } from 'react';
import { MouseContext } from './CustomCursor/CursorManager';

export default function Footer() {
	const { cursorChangeHandler } = useContext(MouseContext);
	const hoverStart = (_, type) => {
		cursorChangeHandler(_, type);
	};
	const hoverStop = (_, type) => {
		cursorChangeHandler(_, type);
	};
	const tablet = window.innerWidth < 768;
	return (
		<footer className="footer">
			<div className="container">
				<h2 className="footer__heading text-center mt-5">Have anything to ask?</h2>
				<h3 className="footer__heading text-center">I'm thrilled to answer :) </h3>
				<a
					href="mailto:m.main2402@gmail.com"
					className="btn btn__cta"
					onMouseEnter={() => hoverStart(null, 'button')}
					onMouseLeave={() => hoverStop(null, '')}
				>
					Say Hi
				</a>
				<div className={tablet ? 'social  mb-2' : 'social ml-auto mb-2'}>
					<h4 className="heading text__secondary ">Let&apos;s Connect</h4>
					<div className="social__links">
						<a href="https://www.linkedin.com/in/main1479/" rel="noreferrer" target="_blank">
							<svg>
								<use xlinkHref={'/icons.svg#icon-linkedin'}></use>
							</svg>
						</a>
						<a href="https://github.com//main1479/" rel="noreferrer" target="_blank">
							<svg>
								<use xlinkHref={'/icons.svg#icon-github'}></use>
							</svg>
						</a>
						<a href="https://twitter.com//main1479/" rel="noreferrer" target="_blank">
							<svg>
								<use xlinkHref={'/icons.svg#icon-twitter'}></use>
							</svg>
						</a>
					</div>
				</div>
			</div>

			<p className="copyright">Design & Developed By Mainul Islam</p>
		</footer>
	);
}
