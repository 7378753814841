import React, { useContext, useState } from 'react';
import { MouseContext } from './CursorManager';
import './style.scss';

// TODO: Hide if cursor not moved
const CustomCursor = () => {
	const { cursorType, src } = useContext(MouseContext);
	const [moving, setMoving] = useState(false);
	const secondaryCursor = React.useRef(null);
	const positionRef = React.useRef({
		mouseX: 0,
		mouseY: 0,
		destinationX: 0,
		destinationY: 0,
		distanceX: 0,
		distanceY: 0,
		key: -1,
	});

	React.useEffect(() => {
		let timeout;
		document.addEventListener('mousemove', (event) => {
			setMoving(true);
			clearTimeout(timeout);
			const { clientX, clientY } = event;

			const mouseX = clientX;
			const mouseY = clientY;

			positionRef.current.mouseX = mouseX - secondaryCursor.current.clientWidth / 2;
			positionRef.current.mouseY = mouseY - secondaryCursor.current.clientHeight / 2;
			timeout = setTimeout(function () {
				setMoving(false);
			}, 6000);
		});

		return () => {};
	}, []);
	React.useEffect(() => {
		const followMouse = () => {
			positionRef.current.key = requestAnimationFrame(followMouse);
			const { mouseX, mouseY, destinationX, destinationY, distanceX, distanceY } =
				positionRef.current;
			if (!destinationX || !destinationY) {
				positionRef.current.destinationX = mouseX;
				positionRef.current.destinationY = mouseY;
			} else {
				positionRef.current.distanceX = (mouseX - destinationX) * 0.1;
				positionRef.current.distanceY = (mouseY - destinationY) * 0.1;
				if (
					Math.abs(positionRef.current.distanceX) + Math.abs(positionRef.current.distanceY) <
					0.1
				) {
					positionRef.current.destinationX = mouseX;
					positionRef.current.destinationY = mouseY;
				} else {
					positionRef.current.destinationX += distanceX;
					positionRef.current.destinationY += distanceY;
				}
			}
			if (secondaryCursor && secondaryCursor.current)
				secondaryCursor.current.style.transform = `translate3d(${destinationX}px, ${destinationY}px, 0)`;
		};
		followMouse();
	}, []);

	return (
		<>
			{moving ? (
				<div
					ref={secondaryCursor}
					className={src ? 'cursor project-list' + cursorType : 'cursor ' + cursorType}
				>
					{src && <div className="cursor__inner" style={{ backgroundImage: `url(${src})` }}></div>}
				</div>
			) : null}
		</>
	);
};

export default CustomCursor;
