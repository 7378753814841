import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeaturedProjects from '../components/FeaturedProjects';
import Footer from '../components/Footer';
import Header, { headerAnim } from '../components/Header';
import OtherProjects from '../components/ProjectList';
import useLocoScroll from '../hooks/useLocoScroll';
import Loader, { loaderAnim } from '../components/Loader';
import { MouseContext } from '../components/CustomCursor/CursorManager';

export default function Home() {
  const [preloader, setPreloader] = useState(true);
  const scrollRef = useLocoScroll(!preloader);
  const { cursorChangeHandler } = useContext(MouseContext);
  const hoverStart = (_, type) => {
    cursorChangeHandler(_, type);
  };
  const hoverStop = (_, type) => {
    cursorChangeHandler(_, type);
  };
  const preload = () => {
    setPreloader(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      preload();
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  useLayoutEffect(() => {
    loaderAnim(preloader);
    headerAnim(preloader);
  }, [preloader]);
  return (
    <>
      <Loader />
      {!preloader && (
        <main ref={scrollRef}>
          <Header type="home" />
          <FeaturedProjects scrollRef={scrollRef} />
          {/* About Quote */}
          <section className="about-quote">
            <div className="container text-center container__small">
              <h3 className="heading ch-30 mx-auto mb-10">
                I am incredibly passionate about developing high-quality
                websites that are seamless, meaningful, and leave a lasting
                impact.
              </h3>
              <Link
                to="/about"
                className="btn btn__primary"
                onMouseEnter={() => hoverStart(null, 'button')}
                onMouseLeave={() => hoverStop(null, '')}>
                About Me
              </Link>
            </div>
          </section>
          {/* Other Projects */}
          <OtherProjects />
          <Footer />
        </main>
      )}
    </>
  );
}
