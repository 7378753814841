import { Route, Switch, useLocation } from 'react-router';
import Home from './pages/Home';
import About from './pages/About';
import CustomCursor from './components/CustomCursor';
import CursorManager from './components/CustomCursor/CursorManager';
import './sass/main.scss';
import NotFound from './pages/NotFound';
import Case from './pages/Case';
import { useLayoutEffect } from 'react';

function App() {
	const location = useLocation();

	useLayoutEffect(() => {
		setTimeout(() => {
			const animateit = function (e) {
				const { offsetX: x, offsetY: y } = e,
					{ offsetWidth: width, offsetHeight: height } = this,
					move = 50,
					xMove = (x / width) * (move * 2) - move,
					yMove = (y / height) * (move * 2) - move;

				this.style.transform = `translate(${xMove}px, ${yMove}px)`;

				if (e.type === 'mouseleave') this.style.transform = '';
			};

			document.querySelectorAll('a.btn').forEach((btn) => {
				btn.addEventListener('mousemove', function (e) {
					window.requestAnimationFrame(animateit.bind(btn, e));
				});
				btn.addEventListener('mouseleave', function (e) {
					window.requestAnimationFrame(animateit.bind(btn, e));
				});
			});
		}, 5000);
	}, [location]);

	return (
		<>
			<CursorManager>
				<CustomCursor />
				<main id="scroll-container">
					<Switch location={location} key={location.pathname}>
						<Route path="/project/:slug" exact>
							<Case />
						</Route>
						<Route path="/about" exact>
							<About />
						</Route>
						{/* <Route path="/contact" exact>
							<Contact />
						</Route>
						<Route path="/work-process" exact>
							<WorkProcess />
						</Route> */}
						<Route path="/" exact>
							<Home />
						</Route>
						<Route path="*" exact>
							<NotFound />
						</Route>
					</Switch>
				</main>
			</CursorManager>
		</>
	);
}

export default App;
