import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { MouseContext } from './CustomCursor/CursorManager';
import ScrollTrigger from 'gsap/ScrollTrigger';
import featuredProjectsData from '../featuredProjectsData.json';
import gsap from 'gsap';

export default function FeaturedProjects({ scrollRef }) {
	const [featuredProjects] = useState(featuredProjectsData);
	const ref = useRef(null);
	const { cursorChangeHandler } = useContext(MouseContext);
	const hoverStart = (_, type) => {
		cursorChangeHandler(_, type);
	};
	const hoverStop = (_, type) => {
		cursorChangeHandler(_, type);
	};

	useEffect(() => {
		if (window.innerWidth >= 1025) {
			setTimeout(() => {
				const sections = gsap.utils.toArray('.featured__projects-item');
				gsap.to(sections, {
					xPercent: -100 * (sections.length - 1),
					ease: 'none',
					scrollTrigger: {
						start: 'top -=60',
						trigger: ref.current,
						scroller: scrollRef.current,
						pin: true,
						scrub: 0.5,
						snap: 1 / (sections.length - 1),
						end: () => `+=100%`,
					},
				});
				ScrollTrigger.refresh();
				ScrollTrigger.update();
			}, 100);
		}
	}, [scrollRef]);

	return (
		<section className="featured">
			<div className="container">
				<h2 className="section__heading mb-5">
					Featured <span className="outline__text">Projects</span>
				</h2>
				<div className="container container__small">
					<div className="featured__projects" ref={ref}>
						{featuredProjects?.map((item) => {
							const { slug, projectName, projectTitle, projectTags, featuredImage } = item.fields;

							return (
								<React.Fragment key={slug}>
									<div className="featured__projects-item" id={slug}>
										<div
											className="item__info"
											// data-scroll
											// data-scroll-target={`#${slug}`}
											// data-scroll-sticky
										>
											<h5 className="project__name mb-2">{projectName}</h5>
											<Link
												to={'/project/' + slug}
												className="link"
												onMouseEnter={() => hoverStart(null, 'project-view')}
												onMouseLeave={() => hoverStop(null, '')}
											>
												<h3 className="project__title">{projectTitle}</h3>
											</Link>
											<p className="project__tags">{projectTags}</p>
										</div>
										<Link
											to={`/project/${slug}`}
											className="item__preview"
											onMouseEnter={() => hoverStart(null, 'project-view')}
											onMouseLeave={() => hoverStop(null, '')}
										>
											<img src={featuredImage.fields.file.url} alt="project Name" />
										</Link>
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
}
